import * as React from "react";
import {Anchor, Col, Row, Typography} from "antd";
import styled from "styled-components";
import Examples from "./Examples";
import DataSourceInfo from "./DataSourceInfo";
import CodeExamples from "./CodeExamples";
import TextWithLinks from "./TextWithLinks";
import DisplayLink from "./shared/DisplayLink";
import { FeedbackModal } from "./shared/FeedbackModal";

const {Title, Paragraph} = Typography;

const StyledRow = styled(Row)`
    .ant-typography:not(a),
    body,
    li
    {
        color: ${props => props.theme.colors.darkGray};
    }
`;

const StyledTitle = styled(Title)`
    font-family: Montserrat, sans-serif;

    && {
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: 0.75em;
    }
`;

const StyledSubtitle = styled(Title)`
    font-family: Montserrat, sans-serif;

    && {
        font-size: 1.75rem;
    }
`;

const Column = styled(Col)`
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.125rem;
`;

const StyledParagraph = styled(Paragraph)`
    span,
    a {
        font-size: 1.125rem;
    }

    span + a,
    a + span {
        margin-left: 0.25em;
    }

    && {
        margin-bottom: 0.75em;
    }
`;

const StyledCol = styled(Col)`
    border-left: 2px solid ${props => props.theme.colors.lightGray};
    .ant-affix {
        top: 96px !important;
    }
    
`;

const StyledAnchor = styled(Anchor)`
    &&& {
        .ant-anchor {
            padding-inline: 1rem;
            &::before {
                border: none;
            }
            
            .ant-anchor-link-title {
                color: ${props => props.theme.colors.darkGray};
            }
        }
    }
`;

const AnchorTitle  = styled(Title)`
    && {
        font-size: 1.25rem;
        font-weight: ${props => props.theme.fontWeight.semibold};
        margin: 2rem 0 1rem 1rem;
    }
`;

const StyledLi = styled.li`
    p span {
        font-size: inherit;
    }
`

const ExpectationDetails = ({expectation, location}) => {
    const pageContent = [
        {
            key: 'description',
            href: '#description',
            title: 'Description',
            hasContent: Boolean(expectation.description.long_description.length),
            content: <StyledParagraph>
                        <TextWithLinks description={expectation.description.long_description}/>
                    </StyledParagraph>
        },
        {
            key: 'data-sources',
            href: '#data-sources',
            title: 'Data sources',
            hasContent: Boolean(expectation.supported_data_sources.length),
            content: <DataSourceInfo supportedDataSources={expectation.supported_data_sources}/>
        },
        {
            key: 'args',
            href: '#args',
            title: 'Args',
            hasContent: Boolean(expectation.description.args.length),
            content: <>
                        <StyledSubtitle level={2}>Args</StyledSubtitle>
                        <ul>{expectation.description.args.map((arg, i) => <li key={i}>{arg}</li>)}</ul>
                    </>
        },
        {
            key: 'examples',
            href: '#examples',
            title: 'Examples',
            hasContent: Boolean(expectation.description.example_data) || Boolean(expectation.description.code_examples.codeExamples),
            content: <>
                        <StyledSubtitle level={2}>Examples</StyledSubtitle>
                        <Examples exampleData={expectation.description.example_data}/>
                        <CodeExamples codeExamples={expectation.description.code_examples.codeExamples}/>
                     </>
        },
        {
            key: 'notes',
            href: '#notes',
            title: 'Notes',
            hasContent: Boolean(expectation.description.notes.length),
            content: <>
                        <StyledSubtitle level={2}>Notes</StyledSubtitle>
                        <TextWithLinks description={expectation.description.notes}/>
                        <ul>{expectation.description.notes.map((note, i) => <StyledLi key={i}><TextWithLinks
                            description={note}/></StyledLi>)}</ul>
                    </>
        },
        {
            key: 'related-expectations',
            href: '#related-expectations',
            title: 'Related Expectations',
            hasContent: Boolean(expectation.description.see_also.length),
            content: <>
                        <StyledSubtitle level={2}>Related Expectations</StyledSubtitle>
                        <ul>{expectation.description.see_also.map((link, i) => <li key={i}><DisplayLink
                            linkObject={link}/>
                        </li>)}</ul>
                    </>
        }
    ];

    const anchorContent = pageContent.filter(section => section.hasContent);
    const expectationHasSection = (key: string) => (pageContent.find(section => section.key === key).hasContent);

    return (<StyledRow>
            <Column style xs={24} lg={18}>
                <StyledTitle level={1}>{expectation.title}</StyledTitle>
                {
                    pageContent.filter(section => expectationHasSection(section.key)).map(section =>
                        <section id={section.key}>
                            { section.content }
                        </section>
                    )
                }
                <FeedbackModal location={location}></FeedbackModal>
            </Column>
            <StyledCol offset={2} xs={0} lg={4}>
                <AnchorTitle level={3}>ON THIS PAGE</AnchorTitle>
                <StyledAnchor items={anchorContent}/>
            </StyledCol>
        </StyledRow>
    );
};

export default ExpectationDetails;
